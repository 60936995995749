import * as _ from 'lodash';

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  DetailsConstants,
  DetailsSortableColumnList,
  ExcludeDetailsColumnList,
  LineageConstants,
  MDMAddressFieldConstants,
  MDMCommonConstants,
  MaxDetailsWidthColumnList,
  ProfileDetailsTableConstants,
} from '../../constants/mdm-common-constants';
import {
  ExcludedKeys,
  MDMProfileCommonConstants,
  ProfileAPIResponseConstants,
  QueryParams,
} from '../../constants/profile-constants';

import { ActivatedRoute } from '@angular/router';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { AgGridSortService } from 'src/app/shared/services/ag-grid-sort.service';
import { AppConfig } from 'src/app/app.config';
import { DateTimeConstants } from 'src/app/shared/constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { FilterTablePipe } from 'src/app/revo-core/filterTablePipe';
import { GoldenValueAddressCellRendererComponent } from './golden-value-address-cell-renderer/golden-value-address-cell-renderer.component';
import { LineageCellRendererComponent } from './lineage-cell-renderer/lineage-cell-renderer.component';
import { MDMService } from 'src/app/mdm/mdm.service';
import { PaggingPipe } from '../../dashboard/matches/mdm-specific-pipes/pagging.pipe';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../../constants/ui-translation';
import { url } from '../../constants/api-urls';

@Component({
  selector: 'mdm-profile-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsComponent implements OnInit {
  @Input() mdmId: string;
  @Input() entitytype: string;
  isHco = false;
  showLineage: boolean;
  lineageLoading: boolean;
  golden: any;
  lineage: any[];
  keys: string[];
  display_primary_profile_details: string[];
  display_profile_details: string[];
  display_primary_identifier_details: string[];
  display_identifier_details: string[];
  min_address_index: any;
  max_address_index: any;
  current_address_index: any;
  display_primary_address_details: string[];
  display_address_details: string[];
  display_communication_details: string[];
  communication_keys: string[];

  chartFilters = [
    { value: MDMCommonConstants.HCP, label: MDMCommonConstants.HCP },
    { value: MDMCommonConstants.HCO, label: MDMCommonConstants.HCO },
  ];

  detailsLoading = true;
  details: any[];
  sortedField = 4;
  headerHeight = 50;
  sortedDirection: string = MDMCommonConstants.DESC;

  page = 1;
  pageSize: number;
  totalRecords = 0;
  searchBy = '';

  viewLabel: string;
  commmon_labels = commmon_labels;
  mdmAddressFieldConstants = MDMAddressFieldConstants;

  environment: EnvironmentConfig;
  dateTimeConstants = DateTimeConstants;
  MDMCommonConstants = MDMCommonConstants;
  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.DETAILS;
  ui_labels1 = mdm_labels.ALL_RECORDS.PROFILE.DETAILS_TABLE;
  periodList = [];
  detailsApiResponseConstants = DetailsConstants;
  allLabel: string;
  past24HoursLabel: string;
  past7DaysLabel: string;
  past30DaysLabel: string;
  past6monthsLabel: string;
  pastYearLable: string;
  selectPeriodLabel: string;
  selectedPeriodValue: string;
  colDefObject = new Map<string, string>();
  colDefs = [];
  gridOptions: object;
  defaultColDef: object;
  mdmAllRecordsHcoProfile = url.allRecordsHcoProfile;
  mdmAllRecordsHcpProfile = url.allRecordsHcpProfile;
  frameworkComponents: object;
  gridApi: any;
  filteredDetails: any[];
  filterTablePipe = new FilterTablePipe();
  pagingPipe = new PaggingPipe();
  rowData: [];
  rowDataList = [];
  addressAttributesToShow = [];
  nonFillerAddressAttributes = [];
  elementsWithListAsValue = []

  excludedKeys: string[] = [
    ExcludedKeys.IS_GOLDEN,
    ExcludedKeys.ADDRESSES,
    ExcludedKeys.COMMUNICATIONS,
    ExcludedKeys.IDENTIFIERS,
    ExcludedKeys.ETY_TYPE,
    ExcludedKeys.ETY_SUB_TYPE,
  ];
  addressExcludedKeys: string[] = [ExcludedKeys.PRI_ADDR_FLG];
  lineageSources: any[];

  _id = '';
  _returnId = '';
  _returnToHco = false;

  translated_labels: any;
  backToProfile: string;
  canNotFindProfileLabel: string;
  showLineageLabel: string;
  error_label: string;
  profile_details_label: string;
  account_details_label: string;
  communication_label: string;
  address_label: string;
  identifier_label: string;
  identifiers_label: string;
  viewMoreDetailsLabel: string;
  viewLessDetailsLabel: string;
  need_to_update_label: string;
  attributes: string;
  golden_values: string;
  source_values: string;
  survivorship_values: string;
  mdmIdLabel = MDMCommonConstants.MDM_ID + ':';
  isViewMoreDetails = false;
  detailTableMapping = new Map<string, string>();
  yConstant = MDMCommonConstants.Y;
  columnDefs = {};
  rows = {};
  maxLengthForEllipses = DetailsConstants.MAX_LENGTH_FOR_ELLIPSES;
  primaryLabel: string;

  constructor(
    private service: MDMService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService,
    private _agGridSort: AgGridSortService
  ) {
    this.translated_labels = this._translate.instant([
      this.ui_labels.BACK_TO_PROFILE,
      this.ui_labels.CAN_NOT_FIND_PROFILE,
      this.ui_labels.SOURCES,
      this.ui_labels.SHOW_LINEAGE,
      this.ui_labels.PROFILE_DETAILS,
      this.ui_labels.ACCOUNT_DETAILS,
      this.ui_labels.IDENTIFIER,
      this.ui_labels.IDENTIFIERS,
      this.ui_labels.ADDRESS,
      this.ui_labels.COMMUNICATION,
      this.ui_labels.VIEW_MORE_DETAILS,
      this.ui_labels.VIEW_LESS_DETAILS,
      this.ui_labels.NEED_TO_UPDATE,
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels1.PRIMARY,
      this.ui_labels1.MDM_ID,
      this.ui_labels1.ACCOUNT_NAME,
      this.ui_labels1.ALTERNATE_ACCOUNT_NAME,
      this.ui_labels1.ACCOUNT_TYPE,
      this.ui_labels1.ACCOUNT_SUB_TYPE,
      this.ui_labels1.ACCOUNT_STATUS,
      this.ui_labels1.CLASS_OF_TRADE_CODE,
      this.ui_labels1.COT_CLASSIFICATION,
      this.ui_labels1.COT_CLASSIFICATION_ID,
      this.ui_labels1.COT_FACILITY_TYPE_ID,
      this.ui_labels1.COT_FACILITY_TYPE,
      this.ui_labels1.COT_SPECIALTY_ID,
      this.ui_labels1.COT_SPECIALTY_DESCRIPTION,
      this.ui_labels1.SOURCE,
      this.ui_labels1.SOURCE_ID,
      this.ui_labels1.FIRST_NAME,
      this.ui_labels1.LAST_NAME,
      this.ui_labels1.MIDDLE_NAME,
      this.ui_labels1.PREFIX,
      this.ui_labels1.SUFFIX,
      this.ui_labels1.GENDER,
      this.ui_labels1.TITLE,
      this.ui_labels1.DATE_OF_BIRTH,
      this.ui_labels1.DATE_OF_DEATH,
      this.ui_labels1.PROFESSIONAL_DESIGNATION_CODE,
      this.ui_labels1.PROFESSIONAL_DESIGNATION_DESCRIPTION,
      this.ui_labels1.CUSTOMER_STATUS,
      this.ui_labels1.CUSTOMER_TYPE,
      this.ui_labels1.PRIMARY_SPECIALTY,
      this.ui_labels1.PRIMARY_SPECIALTY_DESCRIPTION,
      this.ui_labels1.SECONDARY_SPECIALTY,
      this.ui_labels1.SECONDARY_SPECIALTY_DESCRIPTION,
      this.ui_labels1.TERTIARY_SPECIALTY,
      this.ui_labels1.TERTIARY_SPECIALTY_DESCRIPTION,
      this.ui_labels1.NO_CONTACT_FLAG,
      this.ui_labels1.PDRP_FLAG,
      this.ui_labels1.PDRP_EFFECTIVE_DATE,
      this.ui_labels1.TARGET_FLAG,
      this.ui_labels1.DMR_FLAG,
      this.ui_labels1.KOL_FLAG,
      this.ui_labels1.KAISER_FLAG,
      this.ui_labels1.AMA_DEAD_FLAG,
      this.ui_labels1.STRT_DT,
      this.ui_labels1.END_DT,
      this.ui_labels1.ADDR_MDM_ID,
      this.ui_labels1.ADDR_LN1,
      this.ui_labels1.ADDR_LN2,
      this.ui_labels1.CITY,
      this.ui_labels1.ST,
      this.ui_labels1.CNTRY,
      this.ui_labels1.LAT,
      this.ui_labels1.LNGTD,
      this.ui_labels1.ZIP_CD,
      this.ui_labels1.ZIP_CD_EXTN,
      this.ui_labels1.PHONE,
      this.ui_labels1.FAX,
      this.ui_labels1.SLN_NM,
      this.ui_labels1.DEA_NM,
      this.ui_labels1.NPI_NM,
      this.ui_labels1.ME_NM,
      this.ui_labels1.ATTRIBUTES,
      this.ui_labels1.GOLDEN_VALUE,
      this.ui_labels1.SOURCE_VALUES,
      this.ui_labels1.SURVIVORSHIP_RULES,
      this.ui_labels1.PRIMARY
    ]);
    this.primaryLabel = this.translated_labels[this.ui_labels1.PRIMARY];
    this.backToProfile = this.translated_labels[this.ui_labels.BACK_TO_PROFILE];
    this.canNotFindProfileLabel = this.translated_labels[
      this.ui_labels.CAN_NOT_FIND_PROFILE
    ];
    this.showLineageLabel = this.translated_labels[this.ui_labels.SHOW_LINEAGE];
    this.error_label = this.translated_labels[
      this.commmon_labels.ERROR_WITH_COLON
    ];
    this.environment = this.config.getConfigObject();
    this.profile_details_label = this.translated_labels[
      this.ui_labels.PROFILE_DETAILS
    ];
    this.account_details_label = this.translated_labels[
      this.ui_labels.ACCOUNT_DETAILS
    ];
    this.address_label = this.translated_labels[this.ui_labels.ADDRESS];
    this.identifiers_label = this.translated_labels[this.ui_labels.IDENTIFIERS];

    this.communication_label = this.translated_labels[
      this.ui_labels.COMMUNICATION
    ];
    this.identifier_label = this.translated_labels[this.ui_labels.IDENTIFIER];
    this.viewMoreDetailsLabel = this.translated_labels[
      this.ui_labels.VIEW_MORE_DETAILS
    ];
    this.viewLessDetailsLabel = this.translated_labels[
      this.ui_labels.VIEW_LESS_DETAILS
    ];
    this.need_to_update_label = this.translated_labels[
      this.ui_labels.NEED_TO_UPDATE
    ];
    this.attributes = this.translated_labels[this.ui_labels1.ATTRIBUTES];
    this.golden_values = this.translated_labels[this.ui_labels1.GOLDEN_VALUE];
    this.source_values = this.translated_labels[this.ui_labels1.SOURCE_VALUES];
    this.survivorship_values = this.translated_labels[
      this.ui_labels1.SURVIVORSHIP_RULES
    ];
    this.addressAttributesToShow = [this.mdmAddressFieldConstants.ADDR_LN1, this.mdmAddressFieldConstants.ADDR_LN2,
    this.mdmAddressFieldConstants.CITY, this.mdmAddressFieldConstants.ST, this.mdmAddressFieldConstants.ZIP_CD];
    this.nonFillerAddressAttributes = [this.mdmAddressFieldConstants.ADDR_LN1, this.mdmAddressFieldConstants.ADDR_LN2,
    this.mdmAddressFieldConstants.CITY, this.mdmAddressFieldConstants.ST, this.mdmAddressFieldConstants.ZIP_CD,
    this.mdmAddressFieldConstants.ZIP_CD_EXTN, this.mdmAddressFieldConstants.CNTRY, this.mdmAddressFieldConstants.LAT,
    this.mdmAddressFieldConstants.LNGTD, this.mdmAddressFieldConstants.VERFN_STAT];
    this.elementsWithListAsValue = [LineageConstants.SRC_ID];

    this.detailTableMapping[
      ProfileDetailsTableConstants.MDM_ID
    ] = this.translated_labels[this.ui_labels1.MDM_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ACCOUNT_NAME
    ] = this.translated_labels[this.ui_labels1.ACCOUNT_NAME];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ALTERNATE_ACCOUNT_NAME
    ] = this.translated_labels[this.ui_labels1.ALTERNATE_ACCOUNT_NAME];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ACCOUNT_TYPE
    ] = this.translated_labels[this.ui_labels1.ACCOUNT_TYPE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ACCOUNT_SUB_TYPE
    ] = this.translated_labels[this.ui_labels1.ACCOUNT_SUB_TYPE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ACCOUNT_STATUS
    ] = this.translated_labels[this.ui_labels1.ACCOUNT_STATUS];
    this.detailTableMapping[
      ProfileDetailsTableConstants.CLASS_OF_TRADE_CODE
    ] = this.translated_labels[this.ui_labels1.CLASS_OF_TRADE_CODE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_CLASSIFICATION
    ] = this.translated_labels[this.ui_labels1.COT_CLASSIFICATION];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_CLASSIFICATION_ID
    ] = this.translated_labels[this.ui_labels1.COT_CLASSIFICATION_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_FACILITY_TYPE_ID
    ] = this.translated_labels[this.ui_labels1.COT_FACILITY_TYPE_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_FACILITY_TYPE
    ] = this.translated_labels[this.ui_labels1.COT_FACILITY_TYPE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_SPECIALTY_ID
    ] = this.translated_labels[this.ui_labels1.COT_SPECIALTY_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.COT_SPECIALTY_DESCRIPTION
    ] = this.translated_labels[this.ui_labels1.COT_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SOURCE
    ] = this.translated_labels[this.ui_labels1.SOURCE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SOURCE_ID
    ] = this.translated_labels[this.ui_labels1.SOURCE_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.FIRST_NAME
    ] = this.translated_labels[this.ui_labels1.FIRST_NAME];
    this.detailTableMapping[
      ProfileDetailsTableConstants.LAST_NAME
    ] = this.translated_labels[this.ui_labels1.LAST_NAME];
    this.detailTableMapping[
      ProfileDetailsTableConstants.MIDDLE_NAME
    ] = this.translated_labels[this.ui_labels1.MIDDLE_NAME];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PREFIX
    ] = this.translated_labels[this.ui_labels1.PREFIX];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SUFFIX
    ] = this.translated_labels[this.ui_labels1.SUFFIX];
    this.detailTableMapping[
      ProfileDetailsTableConstants.GENDER
    ] = this.translated_labels[this.ui_labels1.GENDER];
    this.detailTableMapping[
      ProfileDetailsTableConstants.TITLE
    ] = this.translated_labels[this.ui_labels1.TITLE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.DATE_OF_BIRTH
    ] = this.translated_labels[this.ui_labels1.DATE_OF_BIRTH];
    this.detailTableMapping[
      ProfileDetailsTableConstants.DATE_OF_DEATH
    ] = this.translated_labels[this.ui_labels1.DATE_OF_DEATH];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PROFESSIONAL_DESIGNATION_CODE
    ] = this.translated_labels[this.ui_labels1.PROFESSIONAL_DESIGNATION_CODE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PROFESSIONAL_DESIGNATION_DESCRIPTION
    ] = this.translated_labels[
      this.ui_labels1.PROFESSIONAL_DESIGNATION_DESCRIPTION
      ];
    this.detailTableMapping[
      ProfileDetailsTableConstants.CUSTOMER_STATUS
    ] = this.translated_labels[this.ui_labels1.CUSTOMER_STATUS];
    this.detailTableMapping[
      ProfileDetailsTableConstants.CUSTOMER_TYPE
    ] = this.translated_labels[this.ui_labels1.CUSTOMER_TYPE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PRIMARY_SPECIALTY
    ] = this.translated_labels[this.ui_labels1.PRIMARY_SPECIALTY];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PRIMARY_SPECIALTY_DESCRIPTION
    ] = this.translated_labels[this.ui_labels1.PRIMARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SECONDARY_SPECIALTY
    ] = this.translated_labels[this.ui_labels1.SECONDARY_SPECIALTY];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SECONDARY_SPECIALTY_DESCRIPTION
    ] = this.translated_labels[this.ui_labels1.SECONDARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[
      ProfileDetailsTableConstants.TERTIARY_SPECIALTY
    ] = this.translated_labels[this.ui_labels1.TERTIARY_SPECIALTY];
    this.detailTableMapping[
      ProfileDetailsTableConstants.TERTIARY_SPECIALTY_DESCRIPTION
    ] = this.translated_labels[this.ui_labels1.TERTIARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[
      ProfileDetailsTableConstants.NO_CONTACT_FLAG
    ] = this.translated_labels[this.ui_labels1.NO_CONTACT_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PDRP_FLAG
    ] = this.translated_labels[this.ui_labels1.PDRP_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PDRP_EFFECTIVE_DATE
    ] = this.translated_labels[this.ui_labels1.PDRP_EFFECTIVE_DATE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.TARGET_FLAG
    ] = this.translated_labels[this.ui_labels1.TARGET_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.DMR_FLAG
    ] = this.translated_labels[this.ui_labels1.DMR_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.KOL_FLAG
    ] = this.translated_labels[this.ui_labels1.KOL_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.KAISER_FLAG
    ] = this.translated_labels[this.ui_labels1.KAISER_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.AMA_DEAD_FLAG
    ] = this.translated_labels[this.ui_labels1.AMA_DEAD_FLAG];
    this.detailTableMapping[
      ProfileDetailsTableConstants.STRT_DT
    ] = this.translated_labels[this.ui_labels1.STRT_DT];
    this.detailTableMapping[
      ProfileDetailsTableConstants.END_DT
    ] = this.translated_labels[this.ui_labels1.END_DT];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ADDR_MDM_ID
    ] = this.translated_labels[this.ui_labels1.ADDR_MDM_ID];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ADDR_LN1
    ] = this.translated_labels[this.ui_labels1.ADDR_LN1];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ADDR_LN2
    ] = this.translated_labels[this.ui_labels1.ADDR_LN2];
    this.detailTableMapping[
      ProfileDetailsTableConstants.CITY
    ] = this.translated_labels[this.ui_labels1.CITY];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ST
    ] = this.translated_labels[this.ui_labels1.ST];
    this.detailTableMapping[
      ProfileDetailsTableConstants.CNTRY
    ] = this.translated_labels[this.ui_labels1.CNTRY];
    this.detailTableMapping[
      ProfileDetailsTableConstants.LAT
    ] = this.translated_labels[this.ui_labels1.LAT];
    this.detailTableMapping[
      ProfileDetailsTableConstants.LNGTD
    ] = this.translated_labels[this.ui_labels1.LNGTD];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ZIP_CD
    ] = this.translated_labels[this.ui_labels1.ZIP_CD];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ZIP_CD_EXTN
    ] = this.translated_labels[this.ui_labels1.ZIP_CD_EXTN];
    this.detailTableMapping[
      ProfileDetailsTableConstants.PHONE
    ] = this.translated_labels[this.ui_labels1.PHONE];
    this.detailTableMapping[
      ProfileDetailsTableConstants.FAX
    ] = this.translated_labels[this.ui_labels1.FAX];
    this.detailTableMapping[
      ProfileDetailsTableConstants.SLN_NM
    ] = this.translated_labels[this.ui_labels1.SLN_NM];
    this.detailTableMapping[
      ProfileDetailsTableConstants.DEA_NM
    ] = this.translated_labels[this.ui_labels1.DEA_NM];
    this.detailTableMapping[
      ProfileDetailsTableConstants.NPI_NM
    ] = this.translated_labels[this.ui_labels1.NPI_NM];
    this.detailTableMapping[
      ProfileDetailsTableConstants.ME_NM
    ] = this.translated_labels[this.ui_labels1.ME_NM];
    this.detailTableMapping[
      this.detailsApiResponseConstants.ATTRIBUTES
    ] = this.translated_labels[this.ui_labels1.ATTRIBUTES];
    this.detailTableMapping[
      this.detailsApiResponseConstants.GOLDEN_VALUE
    ] = this.translated_labels[this.ui_labels1.GOLDEN_VALUE];
    this.detailTableMapping[
      this.detailsApiResponseConstants.SOURCE_VALUES
    ] = this.translated_labels[this.ui_labels1.SOURCE_VALUES];
    this.detailTableMapping[
      this.detailsApiResponseConstants.SURVIVORSHIP_RULES
    ] = this.translated_labels[this.ui_labels1.SURVIVORSHIP_RULES];

    this.translated_labels = this._translate.instant([
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels1.ATTRIBUTES,
      this.ui_labels1.GOLDEN_VALUE,
      this.ui_labels1.SOURCE_VALUES,
      this.ui_labels1.SURVIVORSHIP_RULES,
    ]);

    this.colDefObject[
      this.detailsApiResponseConstants.ATTRIBUTES
    ] = this.translated_labels[this.ui_labels1.ATTRIBUTES];
    this.colDefObject[
      this.detailsApiResponseConstants.GOLDEN_VALUE
    ] = this.translated_labels[this.ui_labels1.GOLDEN_VALUE];
    this.colDefObject[
      this.detailsApiResponseConstants.SOURCE_VALUES
    ] = this.translated_labels[this.ui_labels1.SOURCE_VALUES];
    this.colDefObject[
      this.detailsApiResponseConstants.SURVIVORSHIP_RULES
    ] = this.translated_labels[this.ui_labels1.SURVIVORSHIP_RULES];
  }

  getAddressDetails(profile, element, sources, addressDict) {
    let addressRecord = '';
    let primary = null;
    if (!sources.includes(profile[LineageConstants.SRC_NM].value[0])) {
      sources.push(profile[LineageConstants.SRC_NM].value[0]);
    }

    profile[element].forEach(el => {
      const value = [];
      primary = el[LineageConstants.PRI_ADDR_FLG]?.[ProfileAPIResponseConstants.VALUE] ===
        MDMCommonConstants.Y ? true : false;
      this.addressAttributesToShow.forEach(key => {
        if (el[key]?.[ProfileAPIResponseConstants.VALUE] &&
          el[key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === this.MDMCommonConstants.Y) {
          value.push(el[key][ProfileAPIResponseConstants.VALUE]);
        }
      });

      for (const key in profile[element]) {
        if (!this.nonFillerAddressAttributes.includes(key) &&
          profile[element][key]?.[ProfileAPIResponseConstants.VALUE] &&
          profile[element][key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === this.MDMCommonConstants.Y) {
          value.push(profile[element][key][ProfileAPIResponseConstants.VALUE]);
        }
      }
      addressRecord = value.join(', ');
      addressDict.push({
        'key': profile[LineageConstants.SRC_NM].value[0],
        'value': addressRecord,
        'primary': primary
      });
    });
  }

  getIdentifierDetails(profile, element, sourceValueDict) {
    for (const key in profile[element]) {
      if (profile[element][key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === MDMCommonConstants.Y
        && profile[element][key]?.[ProfileAPIResponseConstants.VALUE].length) {
        sourceValueDict.push({
          'key': profile[LineageConstants.SRC_NM].value[0],
          'value': key.split('_')[0] + ':' + profile[element][key].value
        });

      }
    }
  }

  getAndFormatElementDetails(profile, element, sourceValueDict) {
    if (profile[element]?.[ProfileAPIResponseConstants.VALUE].length) {
      const values = profile[element][ProfileAPIResponseConstants.VALUE];
      values.forEach(val => {
        sourceValueDict.push({
          'key': profile[LineageConstants.SRC_NM].value[0],
          'value': val,
        });
      });
    }
  }


  getCommunicationDetails(profile, element, sourceValueDict) {
    profile[element].forEach(el => {
      sourceValueDict.push({
        'key': profile[LineageConstants.SRC_NM].value[0],
        'value': el[LineageConstants.COMM_VAL] + ' ' + el[LineageConstants.COMM_TYPE]
      });
    });
  }

  getElementDetails(profile, element, sourceValueDict) {
    if (profile[element]?.value?.length) {
      sourceValueDict.push({
        'key': profile[LineageConstants.SRC_NM].value[0],
        'value': element !== LineageConstants.SRC_NM ? profile[element].value : profile[element].value[0]
      });
    }
  }

  sortAddresses(sources, addressDict, sourceValueDict) {
    sources.forEach((source) => {
      const profiles = addressDict.filter((prof) => prof['key'] === source);
      const primaryProfiles = profiles.filter((prof) => prof['primary']);
      if (primaryProfiles.length) {
        sourceValueDict.push({
          'key': source,
          'value': this.primaryLabel + ': ' + MDMCommonConstants.Y
        });
        sourceValueDict.push(...primaryProfiles);
      }
    });
    sources.forEach((source) => {
      const profiles = addressDict.filter((prof) => prof['key'] === source);
      const nonPrimaryProfiles = profiles.filter((prof) => !prof['primary']);
      if (nonPrimaryProfiles.length) {
        sourceValueDict.push({
          'key': source,
          'value': this.primaryLabel + ': ' + MDMCommonConstants.N
        });
        sourceValueDict.push(...nonPrimaryProfiles);
      }
    });
  }

  getsurvivorshipRules(element, goldenProfile) {
    if (element === LineageConstants.IDENTIFIERS) {
      const rules = [];
      goldenProfile[element][ProfileAPIResponseConstants.DESC][ProfileAPIResponseConstants.DESC]?.trim().split(',').forEach((att) => {
        const ruledata = att.split(':');
        rules.push({
          'key': ruledata[0] + ': ',
          'value': ruledata[1]
        });
      });
      return rules;
    } else if (element === LineageConstants.ADDRESSES ||
      element === LineageConstants.COMMUNICATIONS) {
      return null;
    } else {
      return goldenProfile[element].desc;
    }
  }

  getAttributeName(element, goldenProfile) {
    if (element === LineageConstants.IDENTIFIERS) {
      return this.identifiers_label;
    } else if (element === LineageConstants.ADDRESSES) {
      return this.address_label;
    } else if (element === LineageConstants.COMMUNICATIONS) {
      return this.communication_label;
    } else {
      return this.detailTableMapping[goldenProfile[element][ProfileAPIResponseConstants.DISPLAY]] ?
        this.detailTableMapping[goldenProfile[element][ProfileAPIResponseConstants.DISPLAY]] :
        goldenProfile[element][ProfileAPIResponseConstants.DISPLAY];
    }
  }

  getGoldenValue(element, goldenProfile) {
    if (element === LineageConstants.IDENTIFIERS) {
      const gvalue = [];
      for (const key in goldenProfile[element]) {
        if (goldenProfile[element][key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === MDMCommonConstants.Y
          && goldenProfile[element][key]?.[ProfileAPIResponseConstants.VALUE].length) {
          gvalue.push(key.split('_')[0] + ':' + LineageConstants.SPLIT_CONSTANT + goldenProfile[element][key].value);
        }
      }
      return gvalue;
    } else if (element === LineageConstants.ADDRESSES) {
      let primary = null;
      const goldenValueList = [];
      const primaryGoldenAddressList = [];
      const nonPrimaryGoldenAddressList = [];
      goldenProfile[element].forEach(el => {
        const gvalue = [];
        primary = el?.[LineageConstants.PRI_ADDR_FLG]?.[ProfileAPIResponseConstants.VALUE];
        this.addressAttributesToShow.forEach(key => {
          if (el[key]?.[ProfileAPIResponseConstants.VALUE] &&
            el[key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === this.MDMCommonConstants.Y) {
            gvalue.push(el[key][ProfileAPIResponseConstants.VALUE]);
          }
        });

        for (const key in goldenProfile[element]) {
          if (!this.nonFillerAddressAttributes.includes(key) &&
            goldenProfile[element][key]?.[ProfileAPIResponseConstants.VALUE] &&
            goldenProfile[element][key]?.[ProfileAPIResponseConstants.TO_DISPLAY] === this.MDMCommonConstants.Y) {
            gvalue.push(goldenProfile[element][key]?.[ProfileAPIResponseConstants.VALUE]);
          }
        }
        primary === MDMCommonConstants.Y ?
          primaryGoldenAddressList.push((gvalue.join(', '))) :
          nonPrimaryGoldenAddressList.push(gvalue.join(', '));

      });
      if (primaryGoldenAddressList.length) {
        goldenValueList.push({
          'key': this.primaryLabel + ': ' + MDMCommonConstants.Y,
          'value': primaryGoldenAddressList
        });

      }
      if (nonPrimaryGoldenAddressList.length) {
        goldenValueList.push({
          'key': this.primaryLabel + ': ' + MDMCommonConstants.N,
          'value': nonPrimaryGoldenAddressList
        });
      }
      return goldenValueList;
    } else if (element === LineageConstants.COMMUNICATIONS) {
      const gvalue = [];
      goldenProfile[element].forEach(el => {
        gvalue.push(el[LineageConstants.COMM_VAL] + ' ' + el[LineageConstants.COMM_TYPE]);
      });
      return gvalue.join(' ');
    } else {
      return goldenProfile[element].value;
    }
  }

  createDetails(data: any[]) {
    const dataArr = [];
    const nonGoldenProfiles = data.filter((profile) => profile.IsGolden.value === MDMCommonConstants.N);
    const goldenProfile = data.filter((profile) => profile.IsGolden.value === MDMCommonConstants.Y)[0];
    const sourceName = [];
    nonGoldenProfiles.forEach((profile) => { sourceName.push(profile[LineageConstants.SRC_NM].value[0]); });
    for (const element in goldenProfile) {
      if (element === LineageConstants.ADDRESSES || element === LineageConstants.IDENTIFIERS ||
        element === LineageConstants.COMMUNICATIONS
        || (goldenProfile[element].display && goldenProfile[element].to_display === MDMCommonConstants.Y)) {

        const attributeName = this.getAttributeName(element, goldenProfile);
        const goldenValue = this.getGoldenValue(element, goldenProfile);
        const survivorshipRules = this.getsurvivorshipRules(element, goldenProfile);

        const sourceValueDict = [];
        const addressDict = [];
        const sources = [];
        nonGoldenProfiles.forEach(
          (profile) => {
            if (element === LineageConstants.ADDRESSES) {
              this.getAddressDetails(profile, element, sources, addressDict);

            } else if (element === LineageConstants.IDENTIFIERS) {
              this.getIdentifierDetails(profile, element, sourceValueDict);
            } else if (element === LineageConstants.COMMUNICATIONS) {
              this.getCommunicationDetails(profile, element, sourceValueDict);
            } else if (this.elementsWithListAsValue.includes(element)) {
              this.getAndFormatElementDetails(profile, element, sourceValueDict);
            } else {
              this.getElementDetails(profile, element, sourceValueDict);
            }

          }
        );
        this.sortAddresses(sources, addressDict, sourceValueDict);

        dataArr.push({
          ATTRIBUTES: attributeName,
          GOLDEN_VALUE: goldenValue,
          SOURCE_DICT: sourceValueDict,
          SURVIVORSHIP_RULES: survivorshipRules,
          SOURCE_NAME: sourceName,
          ADDRESS_LABEL: this.address_label,
          IDENTIFIERS_LABEL: this.identifiers_label,
          COMMUNICATION_LABEL: this.communication_label
        });
      }
    }


    this.rowData = JSON.parse(JSON.stringify(dataArr));
    return dataArr;
  }

  setColumnDefs() {
    this.colDefs = [];
    for (const [key, value] of Object.entries(this.colDefObject)) {
      const detailsColDef = {
        [AgGridColumnDefsConstants.HEADER_NAME]: value,
        [AgGridColumnDefsConstants.FIELD]: key,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: value,
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.WRAP_TEXT]: true,
        [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
        [AgGridColumnDefsConstants.CELL_STYLE]: { 'white-space': 'normal', 'overflow-wrap': 'break-word' },
        [AgGridColumnDefsConstants.MAX_WIDTH]: MaxDetailsWidthColumnList.includes(
          key
        )
          ? 190
          : 500,
        [AgGridColumnDefsConstants.SORTABLE]: DetailsSortableColumnList.includes(
          key
        ),
        [AgGridColumnDefsConstants.UNSORT_ICON]: DetailsSortableColumnList.includes(
          key
        ),
        [AgGridColumnDefsConstants.COMPARATOR]: (valueOne, valueTwo) => {
          return this._agGridSort.stringValueComparator(valueOne, valueTwo);
        },
        [AgGridColumnDefsConstants.CELL_RENDERER]: ExcludeDetailsColumnList.includes(
          key
        )
          ? 'lineageCellRenderer'
          : key === this.detailsApiResponseConstants.GOLDEN_VALUE ? 'goldenValueAddressCellRendererComponent' : '',
        [AgGridColumnDefsConstants.CELL_RENDERER_PARAMS]: {
          source_values: key === this.detailsApiResponseConstants.SOURCE_VALUES,
          environment: this.environment,
          survivorship_values:
            key === this.detailsApiResponseConstants.SURVIVORSHIP_RULES
        },
      };
      this.colDefs.push(detailsColDef);
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
    if (!this.rowDataList.length) {
      this.getLineageData();
    } else if (
      this.rowDataList &&
      this.rowDataList[0].GOLDEN_VALUE !== this._id
    ) {
      this.getLineageData();
    }
    this.gridApi.setColumnDefs(this.colDefs);
    this.gridApi.setRowData(this.rowDataList);
  }

  async getLineageData() {
    await this.service
      .getLineageData(
        this._id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP,
        MDMCommonConstants.Y
      )
      .toPromise()
      .then((res) => {
        this.createDetails(res);
        this.rowDataList = this.rowData;
        this.details = this.rowData;
        this.detailsLoading = false;
        setTimeout(() => {
          this.gridApi.setColumnDefs(this.colDefs);
          this.gridApi.setRowData(this.rowDataList);
        }, 0);
      });
  }

  loadFilteredData() {
    this.filteredDetails = this.details;
    this.loadRowData();
  }

  loadRowData() {
    this.rowData = this.pagingPipe.transform(
      this.filteredDetails,
      this.page,
      this.pageSize
    );
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
  }

  onSort(event) {
    const columnsSortState = event.columnApi.getColumnState();
    let colId: number;
    let colName = '';
    let direction = '';
    let i = 0;
    for (const column of columnsSortState) {
      if (column.sort) {
        colId = i + 1;
        colName = column.colId;
        direction = column.sort;
        break;
      }
      i++;
    }
    this.sortDetails(colId, direction, [colName]);
  }

  sortDetails(col, direction, fields) {
    this.sortedField = col;
    this.sortedDirection = direction;
    this.details.forEach((element) => {
      element.ATTRIBUTES = element.ATTRIBUTES
        ? element.ATTRIBUTES.trim('')
        : element.ATTRIBUTES;
    });
    if (this.sortedDirection === MDMCommonConstants.ASC) {
      this.details = _.sortBy(this.details, fields);
    } else {
      this.details = _.reverse(_.sortBy(this.details, fields));
    }
    this.loadFilteredData();
  }

  ngOnInit() {

    this.pageSize = this.environment.pageSize;

    this.route.params.subscribe((params) => {
      this._id = params[QueryParams.ID];

      this.isHco =
        window.location.href.indexOf(MDMCommonConstants.HCO_WITHIN_URL) >= 0;

      if (this.mdmId) {
        this._id = this.mdmId;
        this.isHco = this.entitytype == MDMCommonConstants.HCO ? true : false
      }
      this.loadLineageData();
      if (this.showLineage) {
        const gridParams = { api: this.gridApi, columnApi: this.columnDefs };
        this.onGridReady(gridParams);
      }
    });
    this.showLineage = false;

    this.frameworkComponents = {
      lineageCellRenderer: LineageCellRendererComponent,
      goldenValueAddressCellRendererComponent: GoldenValueAddressCellRendererComponent,
    };

    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.WRAP_TEXT]: true,
    };

    this.gridOptions = {
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      [AgGridColumnDefsConstants.FRAMEWORK_COMPONENTS]: this
        .frameworkComponents,
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.setColumnDefs();

    this.route.queryParams.subscribe((params) => {
      this._returnId = params[QueryParams.RETURN_ID];
      this._returnToHco =
        params[QueryParams.RETURN_TO] === MDMCommonConstants.HCO;
    });
  }

  loadLineageData() {
    this.lineageLoading = true;
    this.resetKeys();
    this.service
      .getLineageData(
        this._id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP,
        MDMCommonConstants.Y
      )
      .subscribe(
        (data: any) => {
          this.lineageLoading = false;
          if (data.length > 0) {
            this.golden = data.find(
              (d) => d.IsGolden.value === MDMCommonConstants.Y
            );
            if (this.golden) {
              this.golden.id = {
                [ProfileAPIResponseConstants.VALUE]: this.golden.MDM_ID.value,
                [ProfileAPIResponseConstants.DISPLAY]: '',
                [ProfileAPIResponseConstants.DESC]: '',
                [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
              };

              this.service.setEntitySubType(
                (this.golden.ETY_SUB_TYPE || {}).value || ''
              );
            }

            this.lineage = data.filter(
              (d) => d.IsGolden.value === MDMCommonConstants.N
            );
            if (this.lineage) {
              this.lineage.forEach((l) => {
                l.id = {
                  [ProfileAPIResponseConstants.VALUE]: l.MDM_ID.value,
                  [ProfileAPIResponseConstants.DISPLAY]: '',
                  [ProfileAPIResponseConstants.DESC]: '',
                  [ProfileAPIResponseConstants.TO_DISPLAY]:
                    MDMCommonConstants.N,
                };
              });
            }

            const sources = [].concat.apply(
              [],
              this.lineage
                .filter((x) => x.SRC_NM.value && x.SRC_NM.value.length > 0)
                .map((x) => x.SRC_NM.value)
            );
            this.lineageSources = Array.from(
              new Set(sources.map((x) => x.trim().toLowerCase()))
            );
            this.keys = Object.keys(data[0]).filter(
              (k) => !this.excludedKeys.find((e) => e.indexOf(k) >= 0)
            );

            const moveToStart = [
              MDMProfileCommonConstants.SRC_NM,
              MDMProfileCommonConstants.SRC_ID,
            ];
            moveToStart.forEach((k) => {
              const srcIdIndex = this.keys.indexOf(k);
              if (srcIdIndex >= 0) {
                this.keys.splice(srcIdIndex, 1);
                this.keys.unshift(k);
                const temp = this.keys[0];
                this.keys[0] = this.keys[1];
                this.keys[1] = temp;
              }
            });

            this.populateProfileDetailsKeys();
            this.populateIdentifierDetailsKeys();
            this.populateAddressDetailsKeys();
            this.populateCommnunicationDetailsKeys();
            this.isViewMoreDetails = false;
          }
        },
        (exception) => {
          this.lineageLoading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  resetKeys() {
    this.display_primary_profile_details = [];
    this.display_profile_details = [];
    this.display_primary_identifier_details = [];
    this.display_identifier_details = [];
    this.display_primary_address_details = [];
    this.display_address_details = [];
    this.display_communication_details = [];
    this.communication_keys = [];
  }

  populateProfileDetailsKeys() {
    for (let i = 0; i < this.keys.length; i++) {
      if (this.golden[this.keys[i]].to_display === MDMCommonConstants.Y) {
        if (this.golden[this.keys[i]].is_primary) {
          this.display_primary_profile_details.push(this.keys[i]);
        }
        this.display_profile_details.push(this.keys[i]);
      }
    }
  }

  populateIdentifierDetailsKeys() {
    for (const key of Object.keys(this.golden.IDENTIFIERS)) {
      if (this.golden.IDENTIFIERS[key].to_display === MDMCommonConstants.Y) {
        if (this.golden.IDENTIFIERS[key].is_primary) {
          this.display_primary_identifier_details.push(key);
        }
        this.display_identifier_details.push(key);
      }
    }
  }

  populateCommnunicationDetailsKeys() {
    this.communication_keys = [
      ProfileDetailsTableConstants.FAX,
      ProfileDetailsTableConstants.PHONE,
    ];
    this.display_communication_details = this.golden.COMMUNICATIONS;
  }

  populateAddressDetailsKeys() {
    this.min_address_index = 1;
    this.current_address_index = 1;
    if (this.golden.ADDRESSES.length > 0) {
      this.max_address_index = this.golden.ADDRESSES.length;
    } else {
      this.max_address_index = 1;
    }
    for (const key of Object.keys(this.golden.ADDRESSES[0])) {
      if (!this.addressExcludedKeys.includes(key)) {
        if (this.golden.ADDRESSES[0][key].to_display === MDMCommonConstants.Y) {
          if (this.golden.ADDRESSES[0][key].is_primary) {
            this.display_primary_address_details.push(key);
          }
          this.display_address_details.push(key);
        }
      }
    }
  }

  viewMoreProfileDetails() {
    this.isViewMoreDetails = true;
  }

  viewPrimaryProfileDetails() {
    this.isViewMoreDetails = false;
    this.current_address_index = 1;
  }

  decreaseAddressIndex() {
    if (this.current_address_index > this.min_address_index) {
      this.current_address_index = this.current_address_index - 1;
    }
  }

  increaseAddressIndex() {
    if (this.current_address_index < this.max_address_index) {
      this.current_address_index = this.current_address_index + 1;
    }
  }

  pageChanged(newPage) {
    this.page = newPage;
  }
}
