import { Component, OnInit, Input } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { McoCellRendererComponent } from '../../payer/mco-cell-renderer/mco-cell-renderer.component';
import {
  MCOTableConstant,
  PayerWrapperConstants
} from 'src/app/mdm/constants/mdm-common-constants';
import { MDMCommonConstants } from '../../constants/mdm-common-constants';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mco',
  templateUrl: './mco.component.html',
  styleUrls: ['./mco.component.less']
})
export class McoComponent implements OnInit {
  selectedFilter = { value: 'PAYER' }
  gridOptions: object;
  defaultColDef: { [x: string]: string | boolean; };
  environment: EnvironmentConfig;
  noRowsTemplate: string;
  @Input('tableColumns') tableColumns: any;
  widthList = ['MSTR_MDM_ID', 'START_DATE', 'COUNT'];
  domLayout = "autoHeight";

  constructor(private config: AppConfig,
    private service: MDMService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();
  }
  colDefs: ColDef[] = [];
  MCORowData: any[];
  page = 1;
  pageSize: number;
  totalRecords: number;
  frameworkComponents: any;
  sortOrder: string = MDMCommonConstants.DESC;
  sortByColumn: string;
  searchBy: string;
  showAgGridLoader: boolean;
  mcoSub: Subscription;


  ngOnInit(): void {
    this.pageSize = this.environment.pageSize;
    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      defaultColDef: {
        sortable: true,
        unSortIcon: false
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.frameworkComponents = {
      custumizedId: McoCellRendererComponent,
    };
    this.setColumns();
    this.getMCOTableDetails();

  }
  searchMcoOnChanged() {
    this.getMCOTableDetails();

  }
  setColumns() {
    this.showAgGridLoader = true;
    for (const column in this.tableColumns) {
      this.colDefs.push({

        headerName: column.replace(/_/g, ' '),
        field: this.tableColumns[column],
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: column.replace(/_/g, ' '),
        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: this.getCellTooltip(),
        [AgGridColumnDefsConstants.MAX_WIDTH]: this.widthList.includes(this.tableColumns[column])
          ? 180 : this.tableColumns[column] === 'MSTR_MCO_NAME' ? 500
            : 250,
        sortingOrder: ['asc', 'desc'],
        ...((this.tableColumns[column] == MCOTableConstant.MDM_ID
          ? true
          : this.tableColumns[column] == MCOTableConstant.SUSPECTED_SOURCES
            ? true
            : this.tableColumns[column] == MCOTableConstant.MCO_NAME ?
              true
              : this.tableColumns[column] == MCOTableConstant.HIGHEST_MATCH_PERCENTAGE
                ? true
                : false) && { cellRenderer: 'custumizedId' }),
        cellRendererParams: {
          [this.tableColumns[column]]: true,
          routerlink: '/mdm/all-records/payer/profile/',
          state: {
            entity: PayerWrapperConstants.MCO,
            return_page: 'suspected',
          },
        },
      });
    }
  }
  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }
  getMCOTableDetails() {
    this.showAgGridLoader = true;
    if (this.mcoSub) {
      this.mcoSub.unsubscribe();
    }
    this.mcoSub = this.service.getMCOMatches(this.sortByColumn,
      this.sortOrder,
      this.searchBy,
      this.page,
      this.pageSize).subscribe((data) => {
        this.MCORowData = data.data;
        this.totalRecords = data.totalRecords;
        this.showAgGridLoader = false;
      }, (exception) => {
        this.showAgGridLoader = false;
        this.toaster.showError(exception.error.message, '');
      })
  }
  pageChanged(event) {
    this.page = event;
    this.getMCOTableDetails();
  }
  //sort the columns when clicked on table header
  onSort(event) {
    const columnsSortState = event.columnApi.getColumnState();
    let colId: number;
    let colName = '';
    let columnSortOrder = '';
    let i = 0;
    for (const column of columnsSortState) {
      if (column.sort) {
        colId = i + 1;
        colName = column.colId;
        columnSortOrder = column.sort;
        break;
      }
      i++;
    }
    this.sortOrder = columnSortOrder;
    this.sortByColumn = colName;
    this.getMCOTableDetails();
  }
  clearSearch(value) {
    if (!value) {
      this.getMCOTableDetails();

    }
  }
  ngOnDestroy(): void {
    this.mcoSub.unsubscribe();
  }
}


