export const TabConfig={
    "MDM_Tabs": {
        "Suspected_Matches": false,//This will always remain true
        "DCR_Management": true,
        "All_Records": true,//This will always remain true
        "Activity_Log": true//This will always remain true
    },
    "Entity_Type":{
        "HCP": true,
        "HCO": true,
        "PAYER": false,//If Payer is false, then set all the Payer_Tabs to false 
    },
    "Payer_Tabs":{
        "PAYER": true,
        "PLAN": true,
        "PBM": true,
        "MCO": true    
    }
    }